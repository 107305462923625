<script setup lang="ts">
import {
  CartIcon,
  CatalogIcon,
  ChatIcon,
  HomeIcon,
  UserIcon,
} from '@glow/shared/components/icons';
import { useRoutes } from '@glow/shared/lib/routes';
import { type RouteLocationRaw } from 'vue-router';

type TabBarItemDescription = {
  id: number;
  name: string;
  icon: Component;
  link: RouteLocationRaw;
};

const { getUrl } = useRoutes();
const itemDescriptions: TabBarItemDescription[] = [
  {
    id: 1,
    name: 'Главная',
    icon: HomeIcon,
    link: getUrl('clientHome'),
  },
  {
    id: 2,
    name: 'Каталог',
    icon: CatalogIcon,
    link: getUrl('specializations'),
  },
  {
    id: 3,
    name: 'Корзина',
    icon: CartIcon,
    link: getUrl('cart'),
  },
  {
    id: 4,
    name: 'Чат',
    icon: ChatIcon,
    link: getUrl('chat'),
  },
  {
    id: 5,
    name: 'Профиль',
    icon: UserIcon,
    link: getUrl('profile'),
  },
];
</script>

<template>
  <nav class="tab-bar">
    <NuxtLink
      v-for="item in itemDescriptions"
      :key="item.id"
      class="tab-bar-item"
      active-class="tab-bar-item_active"
      :to="item.link"
    >
      <AppIcon :icon="item.icon" />
      <div class="tab-bar-item__name" color="black-secondary">
        {{ item.name }}
      </div>
    </NuxtLink>
  </nav>
</template>

<style scoped lang="scss">
.tab-bar {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  &-item {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    gap: 2px;
    color: var(--app-color-black-secondary);

    &_active {
      color: var(--app-color-primary);
    }
  }
}
</style>
